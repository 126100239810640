import { Button, Skeleton } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useCallback } from 'react'
import { useBalance } from 'wagmi'
import { ChainId } from '@pancakeswap/sdk'
import { formatBigInt } from '@pancakeswap/utils/formatBalance'
import Countdown, { zeroPad } from 'react-countdown'
import { useFreeMint, useFreeMintInfo } from './hooks/useFreeMint'
import {
  BlockListMintToken,
  BlockToken,
  ContentWrap,
  DescItem,
  ImgItem,
  ImgReferral,
  InfoMintItem,
  ItemMintToken,
  ItemTime,
  ListTime,
  MintItem,
  NFTItem,
  NameItem,
  PriceMint,
  TitleMint,
  WrapButton,
  WrapButtonReferral,
} from './style'

const Token = () => {
  const { account } = useActiveWeb3React()
  const { fee } = useFreeMintInfo()
  const { onBuyBNB, isBuying } = useFreeMint()

  const bnbBalance = useBalance({ address: account, chainId: ChainId.BSC })
  const validBnb = bnbBalance?.data?.value > fee
  const disabledBuy = !validBnb

  const handleBuy = useCallback(() => {
    return onBuyBNB(fee?.toString())
  }, [fee, onBuyBNB])

  const timeEnd = new Date('2024-04-01T00:00:00').getTime()
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Sale has ended!!</span>
    }

    return (
      <ListTime>
        <ItemTime className="itemDays">{days} days</ItemTime>
        <ItemTime>{zeroPad(hours)}:</ItemTime>
        <ItemTime>{zeroPad(minutes)}:</ItemTime>
        <ItemTime>{zeroPad(seconds)}</ItemTime>
      </ListTime>
    )
  }

  return (
    <>
      <BlockToken>
        <ContentWrap className="blockContainer">
          <BlockListMintToken>
            <ItemMintToken>
              <NFTItem>
                <ImgItem src="/images/nft-mint.png" />
              </NFTItem>
              <InfoMintItem>
                <NameItem>Arbinu NFTs</NameItem>
                {/* <DescItem>
                  You will earn an extra 30% bonus from your referral link. This reward will be instantly credited to
                  your wallet account when someone else mints an NFT through it.
                </DescItem> */}
                <MintItem>
                  {/* <Countdown date={timeEnd} renderer={renderer} /> */}
                  <TitleMint>Mint Now</TitleMint>
                  {/* {!fee ? <Skeleton width="50px" height="20px" /> : <PriceMint>{formatBigInt(fee, 6)} BNB</PriceMint>} */}
                </MintItem>
                <WrapButton>
                  {!account ? (
                    <ConnectWalletButton width="100%" style={{ background: 'var(--colors-gradientPrimary)' }} />
                  ) : (
                    <Button
                      style={{ boxShadow: 'none' }}
                      width="100%"
                      onClick={handleBuy}
                      disabled={disabledBuy}
                      isLoading={isBuying}
                    >
                      {disabledBuy ? 'Insufficient balance' : isBuying ? 'Minting ...' : 'Mint'}
                    </Button>
                  )}
                </WrapButton>
                <WrapButtonReferral href="/referral">
                  <Button>
                    <ImgReferral src="/images/icReferral.svg" />
                    Referral
                  </Button>
                </WrapButtonReferral>
              </InfoMintItem>
            </ItemMintToken>
          </BlockListMintToken>
        </ContentWrap>
      </BlockToken>
    </>
  )
}
export default Token
