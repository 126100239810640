import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { useNFTFreeContract } from 'hooks/useContract'
import { useCallback } from 'react'
import useCatchTxError from 'hooks/useCatchTxError'
import { useContractReads } from 'wagmi'
import { getNFTFreeAddress } from 'utils/addressHelpers'
import { nftFreeABI } from 'config/abi/nftFree'
import { ChainId } from '@pancakeswap/sdk'

export const useFreeMint = (onDone?: () => void) => {
  const contract = useNFTFreeContract()
  const { fetchWithCatchTxError, loading: isBuying } = useCatchTxError()
  const { callWithGasPrice } = useCallWithGasPrice()

  const onBuyBNB = useCallback(
    async (value: string) => {
      const receipt = await fetchWithCatchTxError(() => {
        return callWithGasPrice(contract, 'mint', [], { value })
      })

      if (receipt?.status && onDone) {
        onDone()
      }
    },
    [fetchWithCatchTxError, onDone, callWithGasPrice, contract],
  )

  return { onBuyBNB, isBuying }
}

export const useFreeMintInfo = () => {
  const { data: results, isLoading } = useContractReads({
    watch: true,
    enabled: true,
    contracts: [
      {
        chainId: ChainId.BSC,
        abi: nftFreeABI,
        address: getNFTFreeAddress(),
        functionName: 'fee',
      },
    ],
  })

  return {
    isLoading,
    fee: results?.[0]?.result ? results?.[0]?.result : undefined,
  }
}
